<template>
    <div class="hero">
        <div class="hero-body pt-3 px-0">

            <register-form />

        </div>
    </div>
</template>

<script>
    import RegisterForm from "@/components/Auth/RegisterForm";

    export default {
        name: "Register",
        title(){ return this.$t('Registration');},
        components: {RegisterForm},
        created() {
            this.$Progress.finish();
        },
    };
</script>

<style scoped>

</style>